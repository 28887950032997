import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { Hide } from "@rebass/hide";
import { graphql } from "gatsby";
import React from "react";
import Icon from "react-icons-kit";
import { Helmet } from "react-helmet";
import { cameraRetro } from "react-icons-kit/fa/cameraRetro";
import { folderOpen } from "react-icons-kit/fa/folderOpen";
import { gavel } from "react-icons-kit/fa/gavel";
import { magic } from "react-icons-kit/fa/magic";
import { search } from "react-icons-kit/fa/search";
import { users } from "react-icons-kit/fa/users";
import { LinkButton } from "../../components/Button";
import Card from "../../components/Card";
import Contact from "../../components/Contact";
import GoogleReviews from "../../components/GoogleReviews";
import Hero from "../../components/Hero";
import ImmoweltBadge from "../../components/ImmoweltBadge";
import Page from "../../components/Page";
import PageBlock from "../../components/PageBlock";
import PageTitle from "../../components/PageTitle";
import ProfileImage from "../../components/ProfileImage";
import References from "../../components/References";
import RoundedImage from "../../components/RoundedImage";
import SectionTitle from "../../components/SectionTitle";
import Service from "../../components/Service";
import Testimonials from "../../components/Testimonials";
import { heart } from "react-icons-kit/fa/heart";
import { trophy } from "react-icons-kit/fa/trophy";
import { comments } from "react-icons-kit/fa/comments";
import SectionTeam from "../../components/SectionTeam";
import { Link } from "gatsby";

const ServiceCard = styled(Card)`
  margin-bottom: 32px;
`;

const BigIcon = styled(Box)`
  text-align: center;
  color: #961452;
  margin-bottom: 32px;
`;

const ImmobilienmaklerGotha = ({ data }) => (
  <Page
    header={
      <Hero fluid={data.homeHeroImage.fluid}>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          css={css`
            max-width: 50em;
          `}
        >
          <Box
            width={[1, null, 2 / 3]}
            css={css`
              text-align: left;
            `}
          >
            <PageTitle>Immobilien in Thüringen erfolgreich verkaufen</PageTitle>
            <p>
              Linda Bäumer ist die Expertin, wenn es um den Verkauf von
              Immobilien in Thüringen geht. Kaum jemand kennt den Thüringer
              Immobilienmarkt so gut wie Linda und ihr Team. Profitieren Sie von
              Lindas Erfahrung und Expertise und verkaufen Sie Ihre Immobilie in
              Thüringen schnell sowie hochprofessionell.
            </p>
            <p>
              <strong>
                Kostenlos beraten lassen:{" "}
                <a
                  href="tel:036450449933"
                  css={css`
                    color: inherit;
                    font-weight: inherit;
                  `}
                >
                  036450 / 44 99 33
                </a>
              </strong>
            </p>
            <Flex alignItems="stretch" flexWrap="wrap">
              <Hide xsmall>
                <ImmoweltBadge />
              </Hide>
              <GoogleReviews />
            </Flex>
          </Box>
          <Hide width={[1, null, 1 / 3]} paddingLeft={5} xsmall small>
            <ProfileImage version={2} />
            <p>
              <strong>Linda Bäumer</strong>
              <br />
              Immobilienexpertin
            </p>
          </Hide>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Immobilien in Thüringen erfolgreich verkaufen"
      meta={[
        {
          name: "description",
          content:
            "Linda Bäumer ist die Expertin, wenn es um den Verkauf von Immobilien in Thüringen geht.",
        },
        {
          name: "robots",
          content: "noindex"
        }
      ]}
    />

    {/* Not needed for the moment */}
    {/*

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} pt={5}>
          <SectionTitle>
            Mit Kranich Immobilien eine Immobilie zu verkaufen ist kinderleicht
          </SectionTitle>
          <p>
            Linda Bäumer und ihr Team haben{" "}
            <strong>jahrelange Erfahrung beim Verkauf von Immobilien</strong> in
            Thüringen. Kranich Immobilien übernimmt für Sie gerne sämtliche
            Aufgaben des Verkaufprozesses. Von der Suche nach dem perfekten
            Käufer bis zur Erledigung der Bürokratie sorgt Kranich Immobilien
            dafür, dass{" "}
            <strong>der Verkauf so reibungslos und einfach wie möglich</strong>{" "}
            wird.
          </p>
          <SectionTitle>
            Unser Ziel ist 100%ige Kundenzufriedenheit
          </SectionTitle>
          <p>
            Wir verpflichten uns, unseren Kunden jederzeit einen professionellen
            Service zu bieten und gleichzeitig{" "}
            <strong>Ehrlichkeit und Integrität</strong> zu wahren. Unser Ziel
            ist immer eine 100%ige Kundenzufriedenheit! Seit vielen Jahren
            überzeugen wir unsere Partner und Kunden mit Fleiß und
            Vertrauenswürdigkeit.
          </p>
        </Box>
      </Flex>
    </PageBlock>

  */}

    <Flex justifyContent="center">
      <Box width={[1, null, "50em"]} my={4} p={3}>
        <Flex justifyContent="center" flexWrap="wrap" mx={-3}>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={trophy} />
            </BigIcon>
            <h4>Jahrelange Erfahrung</h4>
            <p>
              Kranich Immobilien unterstützt Eigentümer seit über 8 Jahren
              erfolgreich beim Verkauf Ihrer Immobilien in Thüringen.
            </p>
          </Box>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={comments} />
            </BigIcon>
            <h4>Transparentes Arbeiten</h4>
            <p>
              Ehrlichkeit und Transparenz ist für Linda das Fundament einer
              erfolgreichen Zusammenarbeit.
            </p>
          </Box>
          <Box width={[1, null, 1 / 3]} px={3}>
            <BigIcon>
              <Icon size={50} icon={heart} />
            </BigIcon>
            <h4>Mit Herz und Verstand</h4>
            <p>
              Kranich Immobilien verliert niemals das Wichtigste aus den Augen -
              die Menschen. Freuen Sie sich auf eine entspannte und freundliche
              Atmosphäre.
            </p>
          </Box>
        </Flex>
      </Box>
    </Flex>

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <SectionTitle center>
            Anspruchsvolle, exklusive und individuelle Immobilienvermarktung vom
            Profi
          </SectionTitle>
          <p style={{ textAlign: "center" }}>
            Aus dem Anspruch heraus „alles aus einer Hand – zum Nutzen unserer
            Kunden“ entwickelte das Team um Linda Bäumer Strukturen, das ein
            Rund-Um-Sorglos Verkaufserlebnis ermöglicht.
          </p>
          <Flex flexWrap="wrap">
            <Service
              icon={search}
              title="Immobilienbewertung"
              description="Bei der Immobilienbewertung wird nicht geschätzt, sondern der Wert Ihrer Immobilie fach- und sachgerecht ermittelt."
            />
            <Service
              icon={cameraRetro}
              title="Fotografie und Exposés"
              description="Aussagekräftige Werbemittel sind das A und O. Deshalb werden für Sie ausschließlich hochprofessionelle Werbemittel in bester Qualität erstellt."
            />
            <Service
              icon={magic}
              title="Vermarktung"
              description="Ihre Immobilie wird proaktiv vermarktet. Mit einer regionalen und überregionalen Platzierung des Objektes können wir reichweitenstark potenzielle Käufer ansprechen."
            />
            <Service
              icon={folderOpen}
              title="Bürokratie überwinden"
              description="Die Bürokratie hat die Immobilienwirtschaft fest im Griff. Aber keine Sorge: Es werden alle notwendigen Unterlagen für Sie bereitgestellt und aufgearbeitet."
            />
            <Service
              icon={users}
              title="Besichtigung und Käuferprüfung"
              description="Von der Anfrage bis zur Besichtigung und Bonitätsprüfung. Es wird der perfekte Käufer auf Grundlage Ihrer Kriterien gefunden."
            />
            <Service
              icon={gavel}
              title="Verträge und Notar"
              description="Sobald der passende Käufer gefunden wurde, werden die letzten Schritte bis zur notariellen Beglaubigung sauber vorbereitet."
            />
          </Flex>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "40em"]} my={5} p={3}>
          <SectionTitle center>Verkaufen Sie Ihre Immobilie von überall aus - Unser spezieller Service für Verkäufer von Außerhalb</SectionTitle>
          <Box width={[1, null, "40em"]} margin="auto">


            Ein Immobilienverkauf ist sehr stressig. Wenn man zudem nicht vor Ort sein kann, steht man unweigerlich vor einer echten Mammutaufgabe. Doch keine Sorge, wir haben die Lösung für Sie! Als einer der besten <Link to="/">Immobilienmakler in Thüringen</Link> bieten wir einen speziellen Service für Verkäufer, die nicht in der Nähe wohnen, aber eine Immobilie hier in Thüringen besitzen und verkaufen möchten.
            <br /><br />
            <strong>Unser Ziel ist es, den Verkauf für Sie so einfach wie möglich zu gestalten, ohne dass Sie persönlich am Verkaufsobjekt erscheinen müssen.</strong>
            <br /><br />
            Wir helfen Ihnen gerne dabei, den erfolgreichen Verkauf Ihrer Immobilie so schnell und unkompliziert wie möglich umzusetzen. Nutzen Sie die Vorteile, die wir Ihnen bieten und <strong>sparen Sie Zeit und Geld</strong>.


          </Box>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} my={4} p={3}>
          <Card collapsed>
            <Flex alignItems="center" flexWrap="wrap" mx={-3}>
              <Box width={[1, 1, 1 / 3]} px={[3, 3, 3]} mb={[3, 3, 0]}>
                <RoundedImage
                  fluid={data.founderImage.fluid}
                  alt="Linda Bäumer"
                />
              </Box>
              <Box width={[1, null, 2 / 3]} px={3}>
                <h2>
                  Linda Bäumer ist die Expertin, wenn es um den Verkauf von
                  Immobilien in Thüringen geht
                </h2>
                <p>
                  Linda Bäumer, Gründerin und Inhaberin von Kranich Immobilien,
                  ist eine der erfolgreichsten Immobilienmaklerinnen in
                  Thüringen. Mit über 8 Jahren Erfahrung weiß sie alles, was man
                  über den Verkauf von Immobilien wissen muss - unabhängig von
                  deren Art, Größe oder Lage. Linda und ihr Team sind stolz
                  darauf, ihren Kunden jederzeit einen{" "}
                  <strong>professionellen Service</strong> zu bieten, kombiniert
                  mit Ehrlichkeit und Integrität. Dank ihrer Sorgfalt und
                  Vertrauenswürdigkeit gehört Kranich Immobilien zu einem der
                  erfolgreichsten Immobilienbüros in ganz Thüringen.
                </p>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <ServiceCard red collapsed>
            <h3>Bewertungen von Immobilien</h3>
            <p>
              Gerne können Sie den Wert Ihrer Immobilie kostenlos mit unserem
              Immobilienbewertungstool ermitteln. Darüber hinaus helfen wir aber
              auch bei der Erstellung eines Gutachtens durch einen
              Sachverständigen.
            </p>
            <div>
              <LinkButton to="/immobilienbewertung/">
                Immobilie bewerten lassen
              </LinkButton>
            </div>
          </ServiceCard>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <ServiceCard red collapsed>
            <h3>Exklusives Homestaging</h3>
            <p>
              Wussten Sie: Weniger als 20 % aller Menschen können sich leere
              Räume möbliert vorstellen und deren Dimensionen richtig
              einschätzen. Als Immobilienmakler weiß ich, dass es für den ersten
              Eindruck keine zweite Chance gibt. Mit dem Homestaging-Service
              verhelfe ich Ihrer Immobilie zum perfekten ersten Eindruck.
            </p>
            <div>
              <LinkButton to="/homestaging/">Mehr zum Homestaging</LinkButton>
            </div>
          </ServiceCard>
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, "50em"]} p={3}>
          <ServiceCard red collapsed>
            <h3>Direkter Ankauf von Immobilien</h3>
            <p>
              Stetig sind wir auf der Suche nach neuen
              Investitionsmöglichkeiten. Bei geeigneten Objekten sind wir gerne
              bereit, Ihnen ein faires und marktgerechtes Angebot zu
              unterbreiten. Dadurch können Sie den Weg des Verkaufes deutlich
              verkürzen.
            </p>
          </ServiceCard>
        </Box>
      </Flex>

      <SectionTeam />

      <Flex justifyContent="center">
        <Box width={[1, null, "70em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Was unsere Kunden über uns sagen</SectionTitle>
          <Testimonials data={data.testimonials} />
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <p>Viele weitere Stimmen zu meiner Arbeit finden Sie auf Google:</p>
            <Box width={[1, "20em"]}>
              <GoogleReviews />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </PageBlock>

    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, null, "60em"]} my={5} p={3} justifyContent="center">
          <SectionTitle center>Referenzobjekte</SectionTitle>
          <Box width={[1, null, "40em"]} margin="auto">
            <p style={{ textAlign: "center" }}>
              Erzählen kann man vieles. Doch ich habe viele Erfolge
              nachzuweisen. Ganz gleich ob Einfamilienhaus, Eigentumswohnung
              oder ungewöhnliche Objekte — aber machen Sie sich doch ein eigenes
              Bild:
            </p>
          </Box>
          <References data={data.references} />
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box width={[1, null, 1 / 2]} my={5} p={3}>
          <Card red>
            <ProfileImage />
            <Box mt={3}>
              <h3>Herausforderungen gemeinsam angehen</h3>
              <p>
                Möchten Sie mit mir über Ihre Immobilie sprechen? Füllen Sie
                dafür bitte das Formular aus. Ich melde mich danach so schnell
                wie möglich bei Ihnen. Einfach, schnell und unverbindlich.
              </p>
              <Contact />
            </Box>
          </Card>
        </Box>
      </Flex>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    homeHeroImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    founderImage: imageSharp(fluid: { originalName: { regex: "/linda_2/" } }) {
      fluid(maxWidth: 500, maxHeight: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    weimarImage: imageSharp(fluid: { originalName: { regex: "/weimar/" } }) {
      fluid(maxWidth: 500, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    references: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/references/" } }
      sort: { fields: [frontmatter___order] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            type
            order
            difficulty
            price
            time
          }
          html
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
          }
          html
        }
      }
    }
  }
`;

export default ImmobilienmaklerGotha;
